import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import regeneratorRuntime from "/home/vsts/work/1/s/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import axios from "axios";
import * as jose from "jose";
import Cookies from "js-cookie";
import { DateTime } from "luxon";
var __awaiter = this && this.__awaiter || function(thisArg, _arguments, P, generator) {
    var adopt = function adopt(value) {
        return _instanceof(value, P) ? value : new P(function(resolve) {
            resolve(value);
        });
    };
    return new (P || (P = Promise))(function(resolve, reject) {
        var fulfilled = function fulfilled(value) {
            try {
                step(generator.next(value));
            } catch (e) {
                reject(e);
            }
        };
        var rejected = function rejected(value) {
            try {
                step(generator["throw"](value));
            } catch (e) {
                reject(e);
            }
        };
        var step = function step(result) {
            result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
        };
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var SESSA = "SESSa";
var SESSR = "SESSr";
var SESSS = "SESSs";
var issuer = process.env.NEXT_PUBLIC_AUTH_URL || "https://identityapi.biocentury.com";
export var getAuthToken = function() {
    return __awaiter(void 0, void 0, void 0, regeneratorRuntime.mark(function _callee() {
        var domain, accessToken, refreshToken, endpoint, data, ref, freshToken, status;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    domain = getDomain();
                    accessToken = Cookies.get(SESSA);
                    if (!(accessToken && isAccessTokenExpired(accessToken))) {
                        _ctx.next = 13;
                        break;
                    }
                    refreshToken = Cookies.get(SESSR);
                    endpoint = "".concat(process.env.NEXT_PUBLIC_AUTH_URL, "/connect/token");
                    data = new URLSearchParams({
                        refresh_token: refreshToken || "",
                        grant_type: "refresh_token",
                        scope: "api1 offline_access openid fullprofile",
                        client_id: "ro.client",
                        client_secret: "secret"
                    });
                    _ctx.next = 8;
                    return axios.post(endpoint, data, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                        }
                    });
                case 8:
                    ref = _ctx.sent;
                    freshToken = ref.data;
                    status = ref.status;
                    if (status === 400 && (freshToken === null || freshToken === void 0 ? void 0 : freshToken.error) === "invalid_grant") {
                        logout();
                    }
                    if (freshToken) {
                        accessToken = freshToken.access_token;
                        setToken(freshToken);
                    }
                case 13:
                    return _ctx.abrupt("return", accessToken);
                case 14:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
};
export var getCurrentUser = function() {
    var token = Cookies.get("SESSa");
    if (!token) {
        return null;
    }
    var payload = jose.decodeJwt(token);
    if (payload) {
        return Object.assign({}, payload);
    }
    return null;
};
export var validateUser = function() {
    return __awaiter(void 0, void 0, void 0, regeneratorRuntime.mark(function _callee() {
        var token, JWKS;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    _ctx.prev = 0;
                    token = Cookies.get("SESSa");
                    if (token) {
                        _ctx.next = 4;
                        break;
                    }
                    return _ctx.abrupt("return", false);
                case 4:
                    JWKS = jose.createRemoteJWKSet(new URL("".concat(issuer, "/.well-known/openid-configuration/jwks")));
                    _ctx.next = 7;
                    return jose.compactVerify(token, JWKS);
                case 7:
                    return _ctx.abrupt("return", true);
                case 10:
                    _ctx.prev = 10;
                    _ctx.t0 = _ctx["catch"](0);
                    console.error(_ctx.t0);
                    return _ctx.abrupt("return", false);
                case 14:
                case "end":
                    return _ctx.stop();
            }
        }, _callee, null, [
            [
                0,
                10
            ]
        ]);
    }));
};
export var logout = function() {
    var domain = getDomain();
    Cookies.remove(SESSA, {
        path: "/",
        domain: domain
    });
    Cookies.remove(SESSR, {
        path: "/",
        domain: domain
    });
    Cookies.remove(SESSS, {
        path: "/",
        domain: domain
    });
};
export var getDomain = function() {
    if ("object" !== "undefined") {
        var res = "." + window.location.hostname.split(".").reverse().splice(0, 2).reverse().join(".") === process.env.NEXT_PUBLIC_BIOCENTURY_DOMAIN ? process.env.NEXT_PUBLIC_BIOCENTURY_DOMAIN : "." + window.location.hostname.split(".").reverse().splice(0, 2).reverse().join(".");
        return res;
    }
    return null;
};
export var setToken = function(token) {
    var domain = getDomain();
    Cookies.set("SESSa", token.access_token, {
        path: "/",
        domain: domain,
        secure: true,
        expires: token.expires_in,
        sameSite: "None"
    });
    Cookies.set("SESSs", "true", {
        path: "/",
        domain: domain,
        expires: token.expires_in
    });
    Cookies.set("SESSr", token.refresh_token, {
        path: "/",
        domain: domain,
        secure: true,
        expires: token.expires_in,
        sameSite: "None"
    });
};
export var isAccessTokenExpired = function(accessToken) {
    var JWT = jose.decodeJwt(accessToken);
    var now = DateTime.local().minus({
        minutes: 1
    });
    var expiration = DateTime.fromMillis(JWT.exp * 1000);
    return expiration <= now;
};
